<template>
	<div class="pencile-area-two">
		<div class="container">
			<div class="row">
				<div class="col-md-12 acurate">
					<div id="pencil-info-two">
						<div class="header-area header-two">
							<div class="row">
								<div class="col-md-2 col-sm-5 col-xs-4">
									<div class="logo-area">
										<a href="/"><img width="100" style="margin-top: -25px" src="/assets/images/Geek-logo.png"
												alt="pencil"></a>
									</div>
								</div>
								<div class="col-md-10 col-sm-7 col-xs-8 text-right">
									<div class="menu-area">
										<ul class="nav nav-pills">
											<li class="active"> <a href="#Profile" data-toggle="tab">
													<span class="fa fa-user-circle"></span>
													Profile</a>
											</li>
											<li><a href="#resume" data-toggle="tab">
													<span class="fa fa-edit"></span>
													Details</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="pencil-content-area-two">
							<div class="tab-content clearfix">
								<div class="tab-pane active pt-page-scaleUpCenter" id="Profile">
									<profile></profile>
								</div>
								<div class="tab-pane pt-page-moveFromRightFade" id="resume">
									<resume></resume>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="widget-area">
			<div class="social-widgect-two sticky-top">
				<ul>
					<li><a target="_blank" href="https://www.github.com/manuelgeek"><i class="fa fa-github"></i></a></li>
					<li><a target="_blank" href="https://www.bitbucket.org/manuelgeek"><i class="fa fa-bitbucket"></i></a></li>
					<li><a target="_blank" href="https://stackoverflow.com/story/manuelgeek"><i
								class="fa fa-stack-overflow"></i></a></li>
					<li><a target="_blank" href="https://www.linkedin.com/in/manuelgeek/"><i class="fa fa-linkedin"></i></a></li>
					<li><a target="_blank" href="https://twitter.com/emashmagak"><i class="fa fa-twitter"></i></a></li>
					<li><a target="_blank" href="https://www.facebook.com/onyach.magak"><i class="fa fa-facebook"></i></a></li>
					<li><a target="_blank" href="https://www.instagram.com/geekmanuel/"><i class="fa fa-instagram"></i></a></li>
				</ul>
			</div>
			<!-- <div class="print-widgect">
                <ul>
                    <li><a href="#"><i class="flaticon-printer"></i> <span>Print</span></a></li>
                </ul>
            </div>
            <div class="download-widgect">
                <ul>
                    <li><a href="#"><i class="flaticon-test"></i> <span>Download</span></a></li>
                </ul>
            </div> -->
		</div>
	</div>
</template>

<script>
import Resume from '@/components/Resume'
import Profile from '@/components/Profile'
export default {
	name: 'home',
	components: {
		Resume, Profile,
	}
}
</script>
