<script>
import SubIcon from './SubIcon.vue';
export default {
  components: {
    SubIcon
  }
}
</script>
<template>
  <div class="resume-area">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="single-resume">
              <div class="resume-title">
                <h3><i class="fa fa-flask"></i> Experience</h3>
              </div>
              <div class="education-list">
                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://www.alxafrica.com/">Sand Technologies
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2021 - Current</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>Senior Full Stack Engineer</h5>
                    <p>
                      Delivering enterprise AI and digital transformation
                      projects for leading organizations and governments around
                      the world
                    </p>
                  </div>
                </div>

                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://droidcon.co.ke">droidconKE
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2018-Current</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>Volunteer Organizer</h5>
                    <p>The biggest Android developers conference in Africa</p>
                  </div>
                </div>

                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://apainsurance.org/">APA Insurance
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2020 - 2021</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>Front End Developer</h5>
                    <p>
                      APA INSURANCE : One of the largest insurers in East Africa
                    </p>
                  </div>
                </div>
                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://appslab.co.ke">Apps:Lab KE
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2017-2021</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>Software Developer</h5>
                    <p>
                      Apps:Lab is a heart of Tech, Creativity, Innovation and
                      Community
                    </p>
                  </div>
                </div>

                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://kava.africa">Kava Africa
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2017-2018</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>Backend Developer</h5>
                    <p>
                      Kava is a Money Management and Lending Platform for
                      Individuals and MSME’s
                    </p>
                  </div>
                </div>
                <div class="single-education">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://www.kenya-airways.com/ke/">Kenya Airways
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <div class="year pull-right">
                      <h4>2017</h4>
                    </div>
                  </div>

                  <div class="education-content">
                    <h5>System Developer and analyst</h5>
                    <p>Department of Business Intelligence and Analysis</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="single-resume">
              <div class="resume-title">
                <h3><i class="fa fa-file-code-o"></i> Open Source</h3>
              </div>
              <div class="employment-list">
                <div class="single-employment">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://github.com/droidconKE">droidconKE
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2013-2016</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Nuxt and Next</h5>
                    <p>
                      Official droidconKE website and other internal websites
                      and dashboards
                    </p>
                  </div>
                </div>

                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://github.com/beamkenya/ex_ussd">Ex Ussd
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Hex package</h5>
                    <p>
                      ExUssd lets you create simple, flexible, and customizable
                      USSD interface. Under the hood ExUssd uses Elixir Registry
                      to create and route individual USSD session.
                    </p>
                  </div>
                </div>
                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link"
                          href="https://www.npmjs.com/package/vuejs-medium-editor">Vuejs Medium Editor
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Npm package</h5>
                    <p>Vue JS like medium editor, Medium Editor wrapper</p>
                  </div>
                </div>
                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://github.com/beamkenya/ex_pesa">Ex Pesa
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Hex package</h5>
                    <p>
                      Payment Library For Most Public Payment API's in Kenya and
                      hopefully Africa(Mpesa, Jenga, iPay etc)
                    </p>
                  </div>
                </div>
                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://hex.pm/packages/phx_izitoast">Phoenix
                          IziToast
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Hex package</h5>
                    <p>
                      This is a Phoenix Elixir IziToast Notification wrapper.
                      https://izitoast.marcelodolza.com, A JavaScript
                      Notifications Toast Library
                    </p>
                  </div>
                </div>
                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link"
                          href="https://github.com/beamkenya/africastalking-elixir">AtEx
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Hex package</h5>
                    <p>
                      An API Wrapper for the AfricasTalking API
                      https://africastalking.com/
                    </p>
                  </div>
                </div>
                <div class="single-employment mb-0">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://github.com/beamkenya/elixir-conf-2022d">Elixir
                          Conf Website
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                  </div>

                  <div class="employment-content">
                    <h5>Nuxt Web App</h5>
                    <p>Official Elixir Conference Africa Website</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="single-resume">
              <div class="resume-title">
                <h3><i class="fa fa-code"></i> Skills</h3>
              </div>
              <div class="certificates-list">
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>JavaScript</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      ES6, NPM, Webpack, Alpine, Inertia, Babel, JS Frameworks
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Typescript</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>Node, GraphQL, Prisma</p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Vue Js</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Vue 2 and 3, Vuetify, Quasar, Nativescript-vue, Nuxt JS,
                      Typescript, TailwindCSS, Bootstrap, Material CSS, SEO,
                      Optimization, TDD
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>React</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      React 16+, Hooks, TS, Next JS, Typescript, Antd, Formik,
                      TDD, Tailwindcss, PWA
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>GraphQL</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>Node, React, Vue, Laravel, Codegen, Nexus, Prisma</p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>PHP</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Laravel, TDD, CI/CD, REST, GraphQL, Composer, Livewire,
                      PHP 8
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Elixir</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Phoenix Framework, TDD, CI/CD, Hex Packages, OTP, Liveview
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Progressive Web Apps</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>Service workers, Workbox, Notifications, WEB APIs etc</p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Firebase</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Hosting, Realtime DB, Firestore, Storage, Messaging,
                      Analytics, Functions etc
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>AWS</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Cognito, Lambda, EC2, S3, ECR, RDMS, Cloudwatch, Kinesis,
                      Dynamo DB, Event Bridge, ECS, Secret Manager, CDK etc
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Dev Ops</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Bitbucket Pipelines, Travis, Gitlab Pipelines, Circle CI,
                      Github Actions, Heroku, AWS, GIT, Docker, Docker Compose,
                      CI/CD
                    </p>
                  </div>
                </div>

                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Databases</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>MySQL, Oracle, PostgreSQL, Mongo</p>
                  </div>
                </div>
                <!-- <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>Atlassian Tools</h4>
                    </div>
                  </div>
                  <div class="employment-content">
                    <p>
                      Bitbucket, Trello, Jira, Confluence, Slack, Opsgenie etc
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="single-resume mt-13">
              <div class="resume-title">
                <h3><i class="fa fa-users"></i> Communities</h3>
              </div>
              <div class="certificates-list">
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://www.reactdevske.org/">React Devs KE
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2017-2018</h4>  </div>                             -->
                  </div>

                  <div class="certificates-content">
                    <h5>Member, Speaker</h5>
                    <p>React developers community based in Nairobi</p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://vuejs-kenya.netlify.app/">Vuejs Kenya
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2017-2018</h4>  </div>                             -->
                  </div>

                  <div class="certificates-content">
                    <h5>Member, Speaker</h5>
                    <p>Where Vue.js Developers Connect,

                      Collaborate & Learn Together</p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://aug.atlassian.com/nairobi/">Atlassian Community
                          Nairobi
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2017-Current</h4>  </div>                             -->
                  </div>

                  <div class="certificates-content">
                    <h5>Member and Speaker</h5>
                    <p>
                      Atlassian Community Nairobi is an award-winning community.
                    </p>
                  </div>
                </div>
                <div class="single-certificates">
                  <div class="university-title fix">
                    <div class="name pull-left">
                      <h4>
                        <a target="_blank" title="site link" href="https://www.meetup.com/Android254/">Android 254
                          <SubIcon />
                        </a>
                      </h4>
                    </div>
                    <!-- <div class="year pull-right"><h4>2017-2018</h4>  </div>                             -->
                  </div>

                  <div class="certificates-content">
                    <h5>Member</h5>
                  <p>Android developers community based in Nairobi</p>
                </div>
              </div>

              <div class="single-certificates">
                <div class="university-title fix">
                  <div class="name pull-left">
                    <h4>
                      <a target="_blank" title="site link" href="https://github.com/beamkenya">Elixir Kenya
                        <SubIcon />
                      </a>
                    </h4>
                  </div>
                  <!-- <div class="year pull-right"><h4>2015-2018</h4>  </div>                             -->
                </div>

                <div class="employment-content">
                  <h5>Member, Speaker, Open Source contributor</h5>
                  <p>Elixir/ BEAM Community in Nairobi, Kenya</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></template>
